<template>
  <div>
    <div class="bg" />
    <div class="container">
      <div class="bar">
        <div class="title">当前积分：{{ integral }}</div>   
        <div class="rb1" @click="next">
          <img src="@/assets/tj_1.png" class="icon" />
          <div class="text">提交</div>
        </div>  
        <div class="rb" @click="goback">
          <img src="@/assets/fh.png" class="icon" />
          <div class="text">返回</div>
        </div> 
      </div>
      <div class="content">
        <div class="items">
          <div class="item">
            <div class="box qq">
              <div class="name">
                <span class="roomcode r">{{ room_name }}</span>
                <span class="headline h1">{{ zone_name }}区</span>
                <span class="headline h1">{{ room_floor }}层</span>
              </div>
              <div class="name" v-if="checkin_type === '1'">
                <span class="headline h1">{{ checkin_date }}（入住）</span>
                <span class="headline h3">-</span>
                <span class="headline h1">{{ checkout_date }}（离店）</span>
                <span class="headline h2">{{ num_day }}天</span>
              </div>          
            </div>           
          </div>
          <div v-if="checkin_type === '1'">
          <div class="item" v-for="nitem in nowitems" :key="nitem.id">
            <div class="box yy2">
              <div class="name dx">
                <img src="@/assets/ddx1.png" class="dxicon" />
              </div>             
            </div>
            <div class="box z1">
              <div class="name tt">
                <span class="roomcode f">{{ nitem.user_name }}</span>
              </div>
              <div class="name">
                <span class="region">{{ nitem.mobile }}</span>
              </div>
              <div class="name">
                <span class="region">{{ nitem.certificate_type }}</span>
              </div>
            </div>
            <div class="box z2">
              <div class="name">                              
                <div v-if="nitem.checkin_type === '0'" class="rb2">
                  <div class="text">扫</div>
                </div>
                <div v-if="nitem.checkin_type === '1'" class="rb2 xie">
                  <div class="text">协</div>
                </div>
              </div>              
            </div>
            <div class="box z3">
              <div class="name">                              
                <img :src="nitem.picture" class="imgpic" />
              </div>              
            </div>           
          </div>         
          </div>
          <div class="item" v-if="checkin_type === '0'">
            <div class="box yy1">
              <div class="name">
                <span class="headline h0 va1">入住时间</span>
              </div>          
            </div>  
            <div class="box zz">
              <div class="name">
                <input type="text" class="datespace" v-model="dateSpace" ref="calendarTigger" placeholder="请选择时间区间" readonly>
              </div>          
            </div>     
            <div class="box yy">
              <div class="name">
                <span class="headline h2 va">共{{ days }}天</span>
              </div>          
            </div>
          </div>
          <div class="item">
            <div class="box qq">
              <div class="name">
                <span class="headline h0">请选择入住人</span>
              </div>                     
            </div>           
          </div>
          <div class="empty" v-if="items.length === 0">
            <div>
              <img src="@/assets/empty.png" />
            </div>
            <div class="text">当前无信息</div>
          </div>
          <div class="item" v-for="item in items" :key="item.id">
            <div class="box yy2" @click="onDxtx(item)">
              <div class="name dx">
                <img src="@/assets/dx1.png" v-if="item.dxflag" class="dxicon" />
                <img src="@/assets/dx2.png" v-if="item.dxflag === false" class="dxicon" />
              </div>             
            </div>
            <div class="box z1">
              <div class="name tt">
                <span class="roomcode f">{{ item.user_name }}</span>
              </div>
              <div class="name">
                <span class="region">{{ item.mobile }}</span>
              </div>
              <div class="name">
                <span class="region">{{ item.certificate_type }}</span>
              </div>
            </div>
            <div class="box z2">
              <div class="name">                              
                <div v-if="item.state === '0'" class="rb2">
                  <div class="text">扫</div>
                </div>
                <div v-if="item.state === '1'" class="rb2 xie">
                  <div class="text">协</div>
                </div>
              </div>              
            </div>
            <div class="box z3">
              <div class="name">                              
                <img :src="item.picture" @click="photograph_jz(item)" class="imgpic" />
              </div>              
            </div>
            <!--<div class="box zz" @click="onDxtx(item)">
              <div class="name tt">
                <span class="roomcode f">{{ item.user_name }}</span>
              </div>
              <div class="name">
                <span class="region">手机号码：{{ item.mobile }}</span>
              </div>
            </div>
            <div class="box yy3" @click="onDxtx(item)">
              <div class="name">                              
                <div v-if="item.checkin_type==='0'" class="rb2">
                  <div class="text">扫</div>
                </div>
                <div v-if="item.checkin_type==='1'" class="rb2 xie">
                  <div class="text">协</div>
                </div>
              </div>              
            </div>
            <div class="box yy1" @click="onDxtx(item)">
              <div class="name">                               
                <img :src="item.imgstr" class="imgpic" />
              </div>              
            </div>-->           
          </div>
        </div>
      </div>
      <!--<div class="list_ll list2">
        <div class="ll">
          <div class="button primary mt" @click="next">提交</div>         
        </div>
      </div>-->
    </div>
  </div>
</template>

<script>
import { get, map } from 'lodash-es';
import { Dialog, Toast } from 'vant';
import qs from 'qs';
import moment from "moment";
import { isEmpty, isTel } from '@/utils';
export default {
  async created() {
    this.type = this.$route.query.type;
    this.unionid = this.$route.query.unionid;
    this.srole = this.$route.query.srole;
    this.room_code = this.$route.query.room_code;
    this.checkin_type = this.$route.query.checkin_type;

    this.queryroom();
    this.query();
    this.querydrz();
    
    this.nowdate = this.getdate();
    this.maxdate = this.computeYmd(this.nowdate,1);

    this.hrefurl = window.sessionStorage.getItem('hrefurl');
    let url = '';
    let ua = navigator.userAgent.toLowerCase();
    if (/iphone|ipad|ipod/.test(ua)) {
      url = this.hrefurl;
    }else{
      url = location.href;
    }

    let config = (
      await this.axios.post(
        '/ctid/testService/jsapisign',
        qs.stringify({
          url: url
        })
      )
    ).data.data;
    window.wx.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: config.appid, // 必填，公众号的唯一标识
      timestamp: config.timestamp, // 必填，生成签名的时间戳 ${wxConfig.timestamp}
      nonceStr: config.nonce_str, // 必填，生成签名的随机串 ${wxConfig.nonceStr}
      signature: config.signature, // 必填，签名，见附录1 ${wxConfig.signature}
      jsApiList: [// 必填，需要使用的JS接口列表，所有JS接口列表见附录2
              'checkJsApi',
							'scanQRCode',
							'getLocation', 
							'chooseImage',//拍照或从手机相册中选图接口
							'previewImage',//预览图片接口
							'uploadImage',//上传图片接口
							'downloadImage',//下载图片接口
							'getLocalImgData'
        ] 
    });
  },
  mounted() {
    this.initCalendar()
  },
  data() {
    return {
      integral: 0,
      type: '',
      unionid: '',
      srole: true,
      room_code: '',
      room_name: '',
      zone_name: '',
      room_floor: '',
      checkin_date: '',
      checkout_date: '',
      num_day: '', 
      state: false,
      listIdNum: '',   
      days: '0',
      startdate: '',
      enddate: '',
      nowdate: '',
      maxdate: '',
      dateSpace: '',  
      checkin_type: '',  
      hrefurl: '',
      nowitems: [
        /*{user_name: '王先生', mobile: '136****5612', certificate_type: '2203**********5612', checkin_type: '0', picture: '/img/nan.png'},
        {user_name: '罗女士', mobile: '131****8633', certificate_type: '2203**********5612', checkin_type: '1', picture: '/img/nv.png'}*/
      ],
      items: [
        /*{dxflag: false, user_name: '王先生', mobile: '136****5612', certificate_type: '2203**********5612', checkin_type: '0', picture: '/img/nan.png'},
        {dxflag: false, user_name: '罗女士', mobile: '131****8633', certificate_type: '2203**********5612', checkin_type: '1', picture: '/img/nv.png'},
        {dxflag: false, user_name: '李先生', mobile: '133****8876', certificate_type: '2203**********5612', checkin_type: '1', picture: '/img/nan.png'}*/
      ]
    };
  },
  methods: {
    getdate() {
      var date = new Date();
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();

      if (month >= 1 && month <= 9) {
          month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
          strDate = "0" + strDate;
      }
      var currentdate = year + "-" + month + "-" + strDate;
      return currentdate;
    },
    initCalendar() {
      this.$calendarSwitch({
          element: this.$refs.calendarTigger,
          monthSize: 2,
          minDate: this.nowdate,
          maxDate: this.maxdate,
          onSuccess: (data) => {           
            this.dateSpace = data.start_date +'~'+ data.end_date;           
            this.startdate = data.start_date;
            this.enddate = data.end_date;
            var a = moment(this.enddate);
            var b = moment(this.startdate);
            var c = a.diff(b, 'days')
            this.days = c;
            //alert(this.dateSpace);
            console.log(this.dateSpace);
          },
          onError: function(msg) {
              console.log(msg)
          }
      });
    },
    computeYmd(val, n) {// 获取给定日期的 n个月后的日期  给定日期格式如：val = 2019-02-26; n = 多少个月
      let str = val.split('-');
      let d = new Date(str[0], str[1], str[2]);
      // 因为getMonth()获取的月份的值只能在0~11之间所以我们在进行setMonth()之前先给其减一
      d.setMonth((d.getMonth()-1) + n);
      let yy1 = d.getFullYear();
      let mm1 = d.getMonth()+1;
      let dd1 = d.getDate()-1;
      if(dd1 == '00'){
        mm1 = parseInt(mm1)-1;
        let new_date = new Date(yy1,mm1,1);
        dd1 = (new Date(new_date.getTime()-1000*60*60*24)).getDate()
      }
      if (mm1 < 10 ) {
        mm1 = '0' + mm1;
      }
      if (dd1 < 10) {
        dd1 = '0' + dd1;
      }
      return yy1 + '-' + mm1 + '-' + dd1;
    },
    DateDiff(sDate1,  sDate2){    //sDate1和sDate2是2006-12-18格式 
      var  aDate,  oDate1,  oDate2,  iDays 
      aDate  =  sDate1.split("-") 
      oDate1  =  new  Date(aDate[1]  +  '-'  +  aDate[2]  +  '-'  +  aDate[0])    //转换为12-18-2006格式 
      aDate  =  sDate2.split("-") 
      oDate2  =  new  Date(aDate[1]  +  '-'  +  aDate[2]  +  '-'  +  aDate[0]) 
      iDays  =  parseInt(Math.abs(oDate1  -  oDate2)  /  1000  /  60  /  60  /24)    //把相差的毫秒数转换为天数 
      return  iDays 
    },
    goback() {
      this.$router.replace({path:'/barcounter/menu_room_state', query:{type:this.type,unionid:this.unionid,srole:this.srole}});
    },
    onDxtx(item) {
      item.dxflag=!item.dxflag;
    },
    async queryroom() {
      let { data } = await this.axios.post(
        '/ctid/TldjService/dj_selectroomtype',
        qs.stringify({
          room_code: this.room_code
        })
      );
      if (data.flag === '0') {
        /*this.room_name = data.data.room_name;
        this.zone_name = data.data.zone_name;
        this.room_floor = data.data.room_floor;    
        this.checkin_date = data.data.checkin_date;
        this.checkout_date = data.data.checkout_date;
        this.num_day = data.data.num_day;*/   

        var test = data.data.replace(/-/g, "+").replace(/_/g, "/");
        var testobj = JSON.parse(decodeURIComponent(escape(window.atob(test.split('.')[1])))).data; 

        this.room_name = testobj.room_name;
        this.zone_name = testobj.zone_name;
        this.room_floor = testobj.room_floor;    
        this.checkin_date = testobj.checkin_date;
        this.checkout_date = testobj.checkout_date;
        this.num_day = testobj.num_day; 

      }
    },
    assistreg() {    
      this.$router.replace({path:'/barcounter/check_in_assist_register', query:{token_id:this.token_id,listIdNum:this.listIdNum,certificate_code:this.certificate_code}});         
    },
    photograph_jz(obj){
      //alert("选择照片");
      window.wx.ready(() => {
        //选择照片
        window.wx.chooseImage({
          count: 1, //张数， 默认9
          sizeType: ['compressed'], //建议压缩图
          sourceType: ['album', 'camera'], // 来源是相册(album)、相机(camera)
          //sourceType: ['camera'], // 来源是相册(album)、相机(camera)
          success: res => {
            var localIds = res.localIds.toString();

            //上传至微信服务器
            window.wx.uploadImage({
              localId: localIds, // 需要上传的图片的本地ID，由chooseImage接口获得
              success: res => {
                var serverId = res.serverId; // 返回图片的服务器端 ID          
                this.uploadImg_jz(serverId,obj);

              },
              fail: function() {
                Toast("上传异常");
              }
            });

          },
          fail: function() {
            Toast("选择异常");
          }
        }); 
      });
      
    },
    async uploadImg_jz(serverId,obj) {
      let { data } = await this.axios.post(
        '/ctid/TldjService/dj_pz_jz',
        qs.stringify({
          pzjzid: serverId,
          username: obj.nick_name,
          IdNum : obj.certificate_code
        })
      );
      if (data.flag === '0') {         
        //obj.picture = data.data.pic_jz ;
        var test = data.data.replace(/-/g, "+").replace(/_/g, "/");
        var testobj = JSON.parse(decodeURIComponent(escape(window.atob(test.split('.')[1])))).data; 
        obj.picture = testobj.pic_jz ;
      }else {
        Toast(data.message);
      }  
        
    },
    async query() {
      let { data } = await this.axios.post('/ctid/TldjService/dj_selectroomuser', qs.stringify({ room_code: this.room_code }));
      //this.nowitems = data.data;
      var test = data.data.replace(/-/g, "+").replace(/_/g, "/");
      this.nowitems = JSON.parse(decodeURIComponent(escape(window.atob(test.split('.')[1])))).data;
    },
    async querydrz() {
      let { data } = await this.axios.post('/ctid/TldjService/dj_blrzdrzlb', qs.stringify({}));
      //this.items = data.data;
      var test = data.data.replace(/-/g, "+").replace(/_/g, "/");
      this.items = JSON.parse(decodeURIComponent(escape(window.atob(test.split('.')[1])))).data;
    },
    async next() {
      //Toast("未开放");
      if(this.state){
        return false;
      }
      this.state = true;

      this.listIdNum = '';
      var f = true;
      for(var j = 0; j < this.items.length; j++) {
        if(this.items[j].dxflag){
          if(f){
            this.listIdNum = this.items[j].certificate_code;
          }else{
            this.listIdNum = this.listIdNum + ',' + this.items[j].certificate_code;
          }
          f = false;      
        }
      }

      /*for(var j = 0; j < this.items.length; j++) {
        if(this.items[j].dxflag){
          this.listIdNum += this.items[j].certificate_code+',';
        }
      }*/  

      if (this.checkin_type==='0') {
        if (this.startdate === null || this.startdate === undefined || this.startdate.length==0 || this.enddate === null || this.enddate === undefined || this.enddate.length==0) {
          Toast('请选择入住时间!');
          this.state = false;
          return;
        }
      }

      let { data } = await this.axios.post(
        '/ctid/TldjService/dj_bpjz_xzbl',
        qs.stringify({
          listidnum:this.listIdNum
        })
      );
      if (data.flag === '0') {
        let { data } = await this.axios.post(
          '/ctid/TldjService/dj_blrzinsertroom',
          qs.stringify({
            roomcode: this.room_code,
            stime: this.startdate,
            etime: this.enddate,
            numday: this.days,
            listidnum: this.listIdNum
          })
        );
        if (data.flag === '0') {
          this.state = false;
          Dialog.confirm({
            showCancelButton: false,
            message: data.message
          }).then(async () => {
            this.$router.replace({path:'/barcounter/menu_room_state', query:{type:this.type,unionid:this.unionid,srole:this.srole}});
          });
        }else {
          this.state = false;
          Toast(data.message);
        }  
      }else{
        this.state = false;
        Toast(data.message);
      }

      
    }   
  }
};
</script>

<style lang="less" scoped>

.bar {
  margin: 48px 40px;
  overflow: hidden;
  .title {
    line-height: 50px;
    color: #fff;
    font-size: 33px;
    float: left;
    margin-top: 10px;
  }
  
  
  .rb {
    height: 64px;
    width: 146px;
    float: right;
    overflow: hidden;
    background: #a46fe6;
    border-radius: 38px;
    margin-right: 10px;
    .icon {
      width: 39px;
      height: 39px;
      margin: 13px;
      display: block;
      float: left;
    }
    
    .text {
      float: left;
      color: #fff;
      font-size: 30px;
      line-height: 64px;
    }
  }
  .rb1 {
    height: 64px;
    width: 146px;
    float: right;
    overflow: hidden;
    background: #a46fe6;
    border-radius: 38px;
    .icon {
      width: 39px;
      height: 39px;
      margin: 13px;
      display: block;
      float: left;
    }
    
    .text {
      float: left;
      color: #fff;
      font-size: 30px;
      line-height: 64px;
    }
  }
  
}

.rb2 {
    height: 80px;
    width: 80px;
    overflow: hidden;
    float: right;
    .icon1 {
      width: 50px;
      height: 50px;
      margin: 8px;
      display: block;

    }
  }
.content {
  padding: 0px;
  margin-top: 40px;
}

.xg {
  width: 660px;
  overflow: hidden;
  margin: 0px auto;
  padding: 30px 0px 20px 0px;
}

.items {
  overflow: hidden;
  padding-bottom: 32px;
  min-height: 616px;
  .item {
    margin: 0px 26px;
    padding: 24px 14px 22px;
    overflow: hidden;
    display: flex;
    border-bottom: 2px solid #eee;
  }
  .box {
    flex: 1;
    overflow: hidden;
    flex:0 0 auto;
    &.zz {
      width: 60%;
    }
    &.yy {
      width: 15%;
    }
    &.yy1 {
      width: 25%;
    }
    &.z1 {
      width: 45%;
    }
    &.z2 {
      width: 10%;
    }
    &.z3 {
      width: 30%;
    }
    &.yy2 {
      width: 15%;     
    }
    &.yy3 {
      width: 36%;
    }
    &.qq {
      width: 100%;
    }
    .name {
      line-height: 50px;
      overflow: hidden;
      font-size: 32px;
      &.tt {
        margin-top: 23px;
      }
      &.dx {
        margin-top: 70px;
      }
      .headline {
        font-size: 26px;
        color: rgb(168, 169, 170);     
        &.h1 {
          margin-right: 30px;
        }
        &.h2 {
          float: right;
        }
        &.h0 {
          font-size: 28px;
          color: rgb(0, 0, 0);           
        }  
        &.h3 {
          margin-right: 35px;
        }
        &.va {
          margin-top: 5px;
        }
        &.va1 {
          height: 60px; 
          line-height: 60px;
          margin-top: 5px;
        }
        &.co {
          color: rgb(17, 34, 131); 
        }
      }
      .imgpic {
        display: block;
        //width: 310px;  
        width: 160px;  
        height: 196px; 
        float: right;
      }
      .datespace {
        width: 100%; 
        height: 60px; 
        line-height: 60px;
        padding-top: 2.5px;
        color: rgb(168, 169, 170);
        font-size: 26px;
        /* 清除默认边框 */
        border: 0;
        /* 清除默认的箭头样式 */
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        background-color: transparent;
        cursor: pointer;  
        &.bt {
          width: 110px; 
        }          
      }
      .dxicon {
        width: 39px;
        height: 39px;
        vertical-align: middle;
      }
      .state {
        display: block;
        font-size: 26px;
        float: right;
        color: rgb(236, 120, 25);
        &.zt {
          color: rgb(4, 141, 61);
        }
        &.xq {
          color: rgb(33, 18, 243);
        }
      }
      .floor {
        display: block;
        float: right;
        font-size: 26px;
        color: rgb(168, 169, 170);
        margin-right: 20px;
      }
      .roomcode {
        margin-right: 80px;
        &.f {
          font-size: 30px;
          color: rgb(168, 169, 170);
        }
        &.r {
          margin-right: 100px;
        }
      }
      .region {
        font-size: 26px;
        color: rgb(168, 169, 170);
      }
      .cx {
        height: 76px;
        vertical-align: middle;
      }
      .icon {
        width: 48px;
        height: 48px;
        vertical-align: middle;
      }
      .input {
        flex: 1;
        margin-right: 15px;
        margin-top: 10px;
        vertical-align: middle;
        line-height: 76px;
        float: left;
        width: 160px;
        border: 0;
        color: #333;
        &.qy {
          font-size: 30px;
          color: rgb(184, 188, 190);
        }
      }
      ::-webkit-input-placeholder { /* WebKit browsers */
        color:rgb(184, 188, 190);
      }
      :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color:rgb(184, 188, 190);
      }
      ::-moz-placeholder { /* Mozilla Firefox 19+ */
        color:rgb(184, 188, 190);
      }
      :-ms-input-placeholder { /* Internet Explorer 10+ */
        color:rgb(184, 188, 190);
      }
      .rb2 {
        height: 50px;
        width: 50px;
        float: right;
        overflow: hidden;
        background: rgb(130, 189, 217);
        margin-top: 70px;
        border-radius: 15px;
        &.xie {
          background: rgb(170, 149, 207);
        }
        .icon {
          width: 30px;
          height: 30px;
          margin: 14px 5px 14px 13px;
          display: block;
          float: left;
        }        
        .text {
          float: left;
          color: #fff;
          font-size: 30px;
          line-height: 50px;
          display: block;
          margin-left: 9px;
        }
      }
      .form_select {
        /* 清除默认边框 */
        border: 0;
        /* 清除默认的箭头样式 */
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        /* 右侧添加小箭头的背景图 */
        background: url('/img/xl1.png') 80px center no-repeat;
        background-size: 26px;
        width: 110px;
        height: 76px;
        background-color: transparent;
        float: right;
        font-size: 30px;
        color:  rgb(184, 188, 190);
        line-height: 76px;
        margin-top: 10px;
        vertical-align: middle;
        &.ri {
          margin-right: 20px;
        }
      }
      
    }
    .name1 {
      line-height: 50px;
      overflow: hidden;
      font-size: 26px;
      .state {
        display: block;
        float: right;
        color: rgb(236, 120, 25);
        &.zt {
          color: rgb(4, 141, 61);
        }
      }
      .floor {
        display: block;
        float: right;
        color: rgb(184, 188, 190);
        margin-right: 20px;
      }
    }
    .unames {
      line-height: 40px;
      font-size: 28px;
      margin-top: 8px;
      color: #918e8e;
      .pz {
        width: 46px;
        height: 46px;
      }
    }
    .del {
      width: 46px;
      height: 46px;
      display: block;
      float: right;
      //margin-top: 18px;
    }
    .edit {
      width: 46px;
      height: 46px;
      display: block;
      float: right;
      margin-right: 20px;
    }
    .view {
      margin-top: 18px;
      width: 198px;
      height: 64px;
      border: 2px solid rgba(0, 29, 255, 1);
      border-radius: 44px;
      text-align: center;
      line-height: 64px;
      color: rgba(0, 29, 255, 1);
      font-size: 28px;
      float: right;
      margin-right: 30px;
    }
  }
}
.empty {
  text-align: center;
  margin-top: 212px;
  img {
    width: 150px;
  }
  .text {
    line-height: 40px;
    font-size: 28px;
    margin-top: 20px;
  }
}
.list_ll {
  width: 710px;
  margin: 0px auto;
  border-radius: 8px;
  overflow: hidden;
  &.list2 {
    margin-top: 15px;
  }
  .ll {
    width: 90%;
    margin: 0px auto;
  }
  
}
</style>
